import baseEntityService from './baseEntityService'

export default class SocialLoginService extends baseEntityService<any> {
  private static instance: SocialLoginService

  private constructor() {
    super('api/social')
  }

  public static getInstance(): SocialLoginService {
    if (!SocialLoginService.instance)
      return new SocialLoginService()

    return SocialLoginService.instance
  }
}
