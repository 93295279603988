import baseEntityService from './baseEntityService'

export default class AnalyticsService extends baseEntityService<any> {
  private static instance: AnalyticsService

  private constructor() {
    super('api/info')
  }

  public static getInstance(): AnalyticsService {
    if (!AnalyticsService.instance)
      return new AnalyticsService()

    return AnalyticsService.instance
  }

  public postWithHeaders(params: any, headers: any) {
    return this.postRequest(this.url, params, headers)
  }
}
