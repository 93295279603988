import axios from 'axios'
import type { AxiosResponse } from 'axios'
import { useRouter } from 'vue-router'
export default abstract class BaseService<T> {
  protected url: string
  protected baseUrl: string
  protected router = useRouter()
  protected constructor(url: string) {
    this.url = url
    this.baseUrl = 'https://api.audioaidynamics.com/'
  }
  
  public async getRequest(url: string, params?: any, config?:any): Promise<AxiosResponse> {
    let urlParams = ''
    if (params) {
      for (const key of Object.keys(params))
        urlParams += `${key}=${params[key]}&`
    }

    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .get(`${this.baseUrl}${url}`, config)
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  public async postRequest(url: string, obj: T, config?:any): Promise<AxiosResponse> {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .post(`${this.baseUrl}${url}`, obj, config)
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  public async patchRequest(url: string, obj: T): Promise<AxiosResponse> {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .patch(`${this.baseUrl}${url}`, obj)
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  public async putRequest(url: string, obj: T): Promise<AxiosResponse> {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .put(`${this.baseUrl}${url}`, obj)
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  public async deleteRequest(url: string): Promise<AxiosResponse> {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .delete(`${this.baseUrl}${url}`)
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

}
