<script setup lang="ts">
import { ref, watch } from 'vue'
import Popup from './aaid/Popup.vue';
import useLoginStore from '../stores/useLoginStore';
import LoginService from '../shared/services/LoginService'
import { AxiosResponse } from 'axios';
import Spinner from './aaid/Spinner.vue'
import DotsLoading from './aaid/DotsLoading.vue'
import { setCookie } from '../shared/helpers/cookieHelpers';
import useUserStore from '../stores/useUserStore';
import MeService from '../shared/services/MeService';
import { useGtag } from 'vue-gtag-next'
import useToastMessageStore from '../stores/useToastMessageStore';
import { useGoogleLogin } from '../composables/useGoogleLogin';
import AaidInput from './aaid/AaidInput.vue';
import MailIcon from './aaid/svgIcons/MailIcon.vue';
import LockIcon from './aaid/svgIcons/LockIcon.vue';
import ShowEyeIcon from './aaid/svgIcons/ShowEyeIcon.vue';
import HideEyeIcon from './aaid/svgIcons/HideEyeIcon.vue';
import { useRoute, useRouter } from 'vue-router';
import RegisterService from '../shared/services/RegisterService';
const registerService = RegisterService.getInstance()
const route = useRoute()
const router = useRouter()
const { initializeGoogleSignIn } = useGoogleLogin();
const username = ref('')
const password = ref('')
const isLoading = ref(false)
const showPassword = ref(false)
const errors: any = ref([])
const meService = MeService.getInstance()
const loginError = ref('')
const VerifyEmailCheck = ref(false)
const cannotVerify = ref(false)
const verificationCount = ref(90)
const isVerificationSending = ref(false)
const socialCookie = ref(false)
const verificationTimeout: any = ref(null)
const verificationInterval: any = ref(null)
const loginStore = useLoginStore()
const onModalClose = () => {
  loginStore.setShowLoginModal(false)
}
const toastMessageStore = useToastMessageStore()
const userStore = useUserStore()
const loginService = LoginService.getInstance()

const checkCookies = () => {
  let cookieComply = localStorage.getItem('cookie-comply')
  if (cookieComply) {
    cookieComply = JSON.parse(cookieComply)
    if (cookieComply && cookieComply.length) {
      socialCookie.value = cookieComply.includes('social_login')
    }
  }
}
watch(()=>loginStore.getShowLoginModal, () => {
  checkCookies()
}, {immediate: true, deep: true})
const handleLogin = (e: any) => {
  VerifyEmailCheck.value = false
  useGtag().event('login', { method: 'Email' })
  e.preventDefault();
  isLoading.value = true
  loginService.login({ email: username.value, password: password.value, rememberMe: false }).then(async (resp: AxiosResponse) => {
    if (resp && resp.data) {
      setCookie('access_token', resp.data.accessToken, (resp.data.accessTokenExpiresIn / 86400))
      setCookie('refresh_token', resp.data.refreshToken, (resp.data.refreshTokenExpiresIn / 86400))
      const token = resp.data.accessToken
      if (token) {
        const user = await meService.get()
        if (user && user.data) {
          userStore.setAuthenticated(true)
          userStore.setUser(user.data)
        }
      }
      loginStore.setShowRegisterModal(false)
      loginStore.setShowLoginModal(false)
      loginStore.setShowForgotPassModal(false)
      isLoading.value = false
      toastMessageStore.setMessage('Successfully Logged in')
      toastMessageStore.setTitle('Success')
      toastMessageStore.setType('success')
      toastMessageStore.setVisible(true)
      if (route.query && route.query.redirect_url) {
        const path: any = route.query.redirect_url
        router.push(path)
      }
    }
  }).catch(e => {
    if (e.response.data.detail) {
      errors.value.push(e.response.data.detail)
    } else if (e.response.data.violations) {
      const newErrors = e.response.data.violations.map((z: any) => `${z.field} ${z.message}`)
      errors.value = newErrors
    } else if (e.response.data.message) {
      errors.value = [e.response.data.message]
    }
    if (e.response.status === 400 && e.response.data.verified !== undefined && !e.response.data.verified) {
      VerifyEmailCheck.value = true
    } else {
      VerifyEmailCheck.value = false
    }
    isLoading.value = false
    // initClearErrors()
  })
}
watch(() => loginStore.getShowLoginModal, (newVal: any) => {
  if (newVal) {
    setTimeout(() => {
      initializeGoogleSignIn()
    }, 10)
  }
})
/* const initClearErrors = () => {
  setTimeout(() => {
    errors.value = []
  }, 5000)
} */
/* const loginWithSpotify = async () => {
  const clientId = import.meta.env.VITE_APP_SPOTIFY_CLIENT_ID;
  const redirectUri = 'https://audioaidynamics.com/spotify-login';
  const scope = 'user-read-email';
  const codeVerifier = generateRandomString(64);
  const hashed = await sha256(codeVerifier)
  const codeChallenge = base64encode(hashed);
  window.localStorage.setItem('code_verifier', codeVerifier);
  const authUrl = new URL("https://accounts.spotify.com/authorize")
  const params = {
    response_type: 'code',
    client_id: clientId,
    scope,
    code_challenge_method: 'S256',
    code_challenge: codeChallenge,
    redirect_uri: redirectUri,
  }
  authUrl.search = new URLSearchParams(params).toString();
  window.location.href = authUrl.toString();
}; */
const loginWithProvider = (provider: string) => {
  useGtag().event('login', { method: provider })
  if (provider === 'spotify') {
    // loginWithSpotify()
  }
}
const showRegisterModal = () => {
  loginStore.setShowLoginModal(false)
  loginStore.setShowForgotPassModal(false)
  loginStore.setShowRegisterModal(true)
}
const showForgotPassModal = () => {
  loginStore.setShowLoginModal(false)
  loginStore.setShowForgotPassModal(true)
  loginStore.setShowRegisterModal(false)
}

const closeErrorMsg = (ind: any) => {
  errors.value.splice(ind, 1)
}
const initVerificationTimer = () => {
  cannotVerify.value = true
  if (verificationTimeout.value) {
    clearTimeout(verificationTimeout.value)
  }
  if (verificationInterval.value) {
    clearInterval(verificationInterval.value)
  }
  verificationTimeout.value = setTimeout(() => {
    cannotVerify.value = false
    clearInterval(verificationInterval.value)
    verificationCount.value = 90
  }, 90000)
  verificationInterval.value = setInterval(() => {
    verificationCount.value--
  }, 1000)
}
const resendVerificationEmail = () => {
  if (cannotVerify.value) return
  isVerificationSending.value = true
  registerService.resendVerificationEmail({ email: username.value, password: password.value }).then((resp: AxiosResponse) => {
    isVerificationSending.value = false
    initVerificationTimer()
    if (resp.data.detail) loginError.value = resp.data.detail
    errors.value = []
  }).catch(() => {
    isVerificationSending.value = false
    initVerificationTimer()
  })
}
</script>
<template>
  <Popup v-if="loginStore.getShowLoginModal" @on-close="onModalClose">
    <template #header>
      <div class="flex w-full justify-center">
        <img src="/logo.webp" alt="logo" width="192px" />
      </div>
    </template>
    <template #body>
      <form class="flex gap-2 flex-col items-center mt-5 w-full">
        <AaidInput label="Your Email" v-model:value="username" type="email" aria-autocomplete="both" autocomplete="on"
          placeholder="name@example.com">
          <template #prependIcon>
            <MailIcon />
          </template>
        </AaidInput>
        <AaidInput label="Password" v-model:value="password" :type="showPassword ? 'text' : 'password'"
          aria-autocomplete="both" autocomplete="on">
          <template #prependIcon>
            <LockIcon />
          </template>
          <template #appendIcon>
            <ShowEyeIcon v-if="!showPassword" @click="showPassword = !showPassword" />
            <HideEyeIcon v-else @click="showPassword = !showPassword" />
          </template>
        </AaidInput>
        <div class="flex flex-col w-full max-w-[360px]">
          <div class="bg-pink-500 mt-3 flex flex-col text-white p-3 rounded-lg shadow-xl" v-for="(item, ind) in errors"
            :key="ind">
            <div
              class="cursor-pointer flex self-end hover:bg-gray-400 hover:text-white font-bold w-fit border-bg-black rounded-full border-2 px-1.5"
              @click="closeErrorMsg(ind)">X</div>
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="flex flex-col w-full max-w-[360px]" v-if="cannotVerify">
          <div class="bg-green-500 flex flex-col text-white p-5 rounded-lg shadow-xl">
            <span>{{ loginError }}</span>
          </div>
        </div>
        <button v-if="VerifyEmailCheck" class="aaid_button mt-5" :disabled="cannotVerify"
          @click.prevent="resendVerificationEmail"><span v-if="!isVerificationSending">Resend verification email <span
              v-if="cannotVerify">in {{ verificationCount }}</span></span>
          <DotsLoading color="bg-white" v-else />
        </button>
        <button @click="handleLogin" :class="VerifyEmailCheck ? 'mt-5' : ''"
          class="aaid_button max-w-[170px] flex gap-4 items-center" type="submit">Login
          <Spinner v-if="isLoading" />
        </button>
      </form>
      <div class="flex flex-col mt-5 justify-center">
        <span class="font-bold text-gray-400 text-center" v-if="socialCookie">-OR-</span>
        <div class="flex w-full flex-col justify-around mt-3 mb-10" v-show="socialCookie">
          <div class="w-full" @click="loginWithProvider('google')" id="googleLogin"></div>
          <!-- <div
            class="mt-4 border-1 border-gray-400 h-[40px] text-center cursor-pointer flex justify-between items-center"
            @click="loginWithProvider('spotify')" id="spotifyLogin">
            <img :src="spotify" width="22px" height="30px" alt="spotify" />
            <span class="basis-full spotifyLogin">Login with Spotify</span>
          </div> -->
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-around items-center flex-col">
        <span @click="showRegisterModal"
          class=" w-full text-center mb-4 cursor-pointer text-blue-400 aaid_button">Register</span>
        <span @click="showForgotPassModal" class="cursor-pointer text-blue-400">Forgot Password?</span>
      </div>
    </template>
  </Popup>
</template>
<style>
#spotifyLogin {
  flex-grow: 1;
  font-family: "Google Sans", arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  font-size: 14px;
  border: 1px solid #dadce0;
  color: #3c4043;
  padding: 0 12px;
}

#login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

#login-container h1 {
  color: #333;
}

#login-container button {
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 10px
}

#login-container input {
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
}
</style>
