import { createApp } from 'vue'
import './style.css'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import { router } from './router'
import en from './translations/en.json'
import es from './translations/es.json'
import VueGtag from "vue-gtag-next";
import { pinia } from './stores/index'
import './shared/helpers/interceptors'
import AaidGames from 'aaid-games';
import 'aaid-games/dist/style.css'
import resetLocalStorage from './shared/helpers/LocalStorageReset'

const messages = {
    en: en,
    es: es
}
const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
})
resetLocalStorage();
const app = createApp(App)
app.use(router)
app.use(i18n)
app.use(AaidGames);
app.use(pinia)
app.use(VueGtag, {
    isEnabled: false,
    appName: 'Audio AI Dynamics',
    property: {
        id: "G-760QLTDMM5"
    },
    params: {
        send_page_view: true,
    }
});

app.mount('#app')