export default function resetLocalStorage() {
    const RESET_FLAG = "aaid_storage_reset_v1";
    const FIRST_VISIT_FLAG = "aaid_first_visit";
  
    if (localStorage.getItem(FIRST_VISIT_FLAG) && !localStorage.getItem(RESET_FLAG)) {
      localStorage.clear();
      localStorage.setItem(RESET_FLAG, "true");
    }
  
    // Mark this user as returning
    localStorage.setItem(FIRST_VISIT_FLAG, "true");
  }
  