
export const isMobile = () => {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const mobilePatterns = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobilePatterns.test(userAgent);
}
import hpcp from '../assets/hpcp.webp'
import soundWaves from '../assets/sound-waves.webp'
import tapper from '../assets/tapper.webp'
import metronome from '../assets/metronome.webp'
import Home from '../assets/house-shadow.webp'
import audioTrimmer from '../assets/trim-audio.webp'
import genreFinder from '../assets/genreFinder.webp'
import mic from '../assets/mic.webp'
import lamejs from 'lamejs'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile, toBlobURL } from '@ffmpeg/util'

// import audioNormalization from '../assets/audioNormalization.webp'
// import loudnessOptimization from '../assets/loudnessOptimization.webp'
export const MainMenu = [{
  name: 'HOME',
  icon: Home,
  link: '/'
}, {
  name: 'MUSIC ANALYZER',
  icon: soundWaves,
  link: 'music-analyzer'
}, {
  name: 'MUSIC GENRE FINDER',
  icon: genreFinder,
  link: 'genre-finder'
}, {
  name: 'AUDIO TRIMMER',
  icon: audioTrimmer,
  link: 'audio-trimmer'
}, {
  name: 'VOICE RECORDER',
  icon: mic,
  link: 'voice-recorder'
}, {
  name: 'HPCP CHROMA',
  icon: hpcp,
  link: 'hpcp-chroma'
}, {
  name: 'BPM TAPPER',
  icon: tapper,
  link: 'bpm-tapper'
}, {
  name: 'ONLINE METRONOME',
  icon: metronome,
  link: 'online-metronome'
}
  /* , {
    name: 'AUDIO NORMALIZATION',
    icon: audioNormalization,
    link: 'audio-normalization'
  }, {
    name: 'LOUDNESS OPTIMIZATION',
    icon: loudnessOptimization,
    link: 'loudness-optimization'
  } */
]

export const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const subscribeUser = (user: any, subscribeService: any) => {
  const dto = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  }
  subscribeService.post(dto)
}

export const generateRandomString = (length: any) => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const values = crypto.getRandomValues(new Uint8Array(length));
  return values.reduce((acc, x) => acc + possible[x % possible.length], "");
}
export const sha256 = async (plain: any) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return window.crypto.subtle.digest('SHA-256', data)
}
export const base64encode = (input: any) => {
  return btoa(String.fromCharCode(...new Uint8Array(input)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

export const random = (min: any, max: any) => Math.random() * (max - min) + min
export const randomColor = (alpha: string) => `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, ${alpha || '0.9'})`

export const encodeToMp3 = (audioBuffer: AudioBuffer): any => {
  const mp3encoder = new lamejs.Mp3Encoder(audioBuffer.numberOfChannels, audioBuffer.sampleRate, 128);
  const mp3Data: Uint8Array[] = [];

  const left = float32ToInt16(audioBuffer.getChannelData(0));
  const right: Int16Array | null = audioBuffer.numberOfChannels > 1
    ? float32ToInt16(audioBuffer.getChannelData(1))
    : null;

  const sampleBlockSize = 1152;

  for (let i = 0; i < audioBuffer.length; i += sampleBlockSize) {
    const leftChunk = left.subarray(i, i + sampleBlockSize);
    const rightChunk = right ? right.subarray(i, i + sampleBlockSize) : null;
    const mp3buf = mp3encoder.encodeBuffer(leftChunk, rightChunk);
    if (mp3buf.length > 0) {
      mp3Data.push(mp3buf);
    }
  }

  const flushBuffer = mp3encoder.flush(); // Finish writing MP3
  if (flushBuffer.length > 0) {
    mp3Data.push(flushBuffer);
  }

  // Concatenate all chunks into a single Uint8Array
  const mp3Blob = new Blob(mp3Data, { type: 'audio/mp3' });

  return mp3Blob
};

// Helper function: Convert Float32Array to Int16Array
function float32ToInt16(buffer: Float32Array): Int16Array {
  const int16Buffer = new Int16Array(buffer.length);
  for (let i = 0; i < buffer.length; i++) {
    int16Buffer[i] = Math.max(-1, Math.min(1, buffer[i])) * 0x7FFF; // Clamp and convert
  }
  return int16Buffer;
}


const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm';
const ffmpeg = new FFmpeg();

export const encodeToM4A = async (urlA: string, outputFileName: string, format: string): Promise<any> => {
  try {
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`ffmpg/ffmpeg-core.wasm`, 'application/wasm'),
      workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
    });
    ffmpeg.writeFile('/input.wav', await fetchFile(urlA));
    const commands = ['-i', '/input.wav', '-c:a', 'aac', '-b:a', '128k']
    if (format === 'm4r') {
      commands.push(`-t`)
      commands.push(`40`)
      commands.push(`-f`)
      commands.push(`ipod`)
    }
    commands.push(`/${outputFileName}`)
    await ffmpeg.exec(commands);
    const data = await ffmpeg.readFile(`/${outputFileName}`);
    const blobOut = new Blob([(data as Uint8Array).buffer], { type: `audio/aac` })
    return blobOut;
  } catch (e) {
    console.error('Error during FFmpeg processing:', e);
    throw e;
  }
};



export const encodeToFlac = async (urlA: string, outputFileName: string, format: string) => {
  try {
    ffmpeg.on('log', (e) => {
      console.log(e)
    })
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
      workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
    });
    ffmpeg.writeFile('/input.wav', await fetchFile(urlA));
    const commands = ['-i', '/input.wav', '-c:a', 'flac', `/${outputFileName}`]
    await ffmpeg.exec(commands);
    const data = await ffmpeg.readFile(`/${outputFileName}`);
    const blobOut = new Blob([(data as Uint8Array).buffer], { type: `audio/${format}` })
    return blobOut;
  } catch (e) {
    console.error('Error during FFmpeg processing:', e);
    throw e;
  }
  
}
