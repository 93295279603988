import { createRouter, createWebHistory } from "vue-router";
import { defineAsyncComponent, h } from "vue";
import PageLoadingComponent from "./components/aaid/PageLoadingComponent.vue";
import AppLayout from "./layouts/AppLayout.vue";
import SideMenuLayout from "./layouts/SideMenuLayout.vue";
import { getCookie } from "./shared/helpers/cookieHelpers";
import { trackRouter } from "vue-gtag-next";
import {
  RoadmapTags,
  PrivacyPolicyTags,
  AnalyzerMetaTags,
  BpmTapperMetaTags,
  ContactMetaTags,
  FaqMetaTags,
  HomeMetaTags,
  AudioTrimmerMetaTags,
  HpcpChromaMetaTags,
  MetronomeMetaTags,
  GenreFinderTags,
  VoiceRecorderMetaTags,
  NoIndexMetaTags,
  DonateMetaTags,
  // AudioNormalizationTags,
  // AudioLoudnessOptimizationTags
} from "./shared/metaTags";
import { storeAnalytics } from './shared/helpers/AnalyticsHelper';
function lazyLoad(component_name: string, params = {}) {
  return {
    render() {
      const async_component = defineAsyncComponent({
        loader: () => import(`./views/${component_name}.vue`),
        loadingComponent: PageLoadingComponent,
        ...params,
      });
      return h(async_component);
    },
  };
}
const routes = [
  {
    path: "/",
    component: lazyLoad("Home"),
    name: "home",
    meta: {
      requiresAuth: false,
      layout: AppLayout,
      metaTags: HomeMetaTags,
      title: "Free Online Audio AI Tools by Audio AI Dynamics - AAID",
      canonicalUrl: 'https://audioaidynamics.com/'
    },
  },
  // { path: '/about', component: lazyLoad('About'), name: 'about', meta: { requiresAuth: false, layout: AppLayout, metaTags: {} } },
  {
    path: "/contact",
    component: lazyLoad("Contact"),
    name: "contact",
    meta: {
      requiresAuth: false,
      layout: AppLayout,
      metaTags: ContactMetaTags,
      title: "Contact Audio AI Dynamics for Custom Support Now",
      canonicalUrl: 'https://audioaidynamics.com/contact'
    },
  },
  {
    path: "/faq",
    component: lazyLoad("Faq"),
    name: "faq",
    meta: {
      requiresAuth: false,
      layout: SideMenuLayout,
      metaTags: FaqMetaTags,
      title: "Audio AI Dynamics: Frequently Asked Questions",
      canonicalUrl: 'https://audioaidynamics.com/faq'
    },
  },
  {
    path: "/privacy-policy",
    component: lazyLoad("PrivacyPolicy"),
    name: "privacyPolicy",
    meta: {
      requiresAuth: false,
      layout: AppLayout,
      metaTags: PrivacyPolicyTags,
      title: "Privacy Policy",
      canonicalUrl: 'https://audioaidynamics.com/privacy-policy'
    },
  },
  // { path: '/terms-and-conditions', component:  lazyLoad('TermsAndConditions'), name: 'termsAndConditions', meta: { requiresAuth: false, layout: AppLayout, metaTags: {}, title: "" } },
  {
    path: "/music-analyzer",
    component: lazyLoad("aaidAnalyzer"),
    name: "music-analyzer",
    meta: {
      requiresAuth: false,
      layout: SideMenuLayout,
      metaTags: AnalyzerMetaTags,
      title: "BPM Finder | Key Finder | Camelot | Music Mood Insights",
      canonicalUrl: 'https://audioaidynamics.com/music-analyzer'
    },
  },
  {
    path: "/bpm-tapper",
    component: lazyLoad("Tapper"),
    name: "tapper",
    meta: {
      requiresAuth: false,
      layout: SideMenuLayout,
      metaTags: BpmTapperMetaTags,
      title: "BPM Tapper | Find Beats Per Minute | BPM Counter | Tap Tempo",
      canonicalUrl: 'https://audioaidynamics.com/bpm-tapper'
    },
  },
  {
    path: "/audio-trimmer",
    component: lazyLoad("AudioTrimmer"),
    name: "Audio Trimmer",
    meta: {
      requiresAuth: false,
      layout: SideMenuLayout,
      metaTags: AudioTrimmerMetaTags,
      title: "Audio Trimmer | MP3 Cutter | Audio Cutter",
      canonicalUrl: 'https://audioaidynamics.com/audio-trimmer'
    },
  },
  {
    path: "/voice-recorder",
    component: lazyLoad("AudioRecorder"),
    name: "Voice Recorder",
    meta: {
      requiresAuth: false,
      layout: SideMenuLayout,
      metaTags: VoiceRecorderMetaTags,
      title: "Voice Recorder | Audio Recorder | Online Audio Recorder",
      canonicalUrl: 'https://audioaidynamics.com/voice-recorder'
    },
  },
  {
    path: "/hpcp-chroma",
    component: lazyLoad("hpcpChroma"),
    name: "hpcp-chroma",
    meta: {
      requiresAuth: false,
      layout: SideMenuLayout,
      metaTags: HpcpChromaMetaTags,
      title: "HPCP Chroma | Vocal Warm Ups | Guitar Tunning",
      canonicalUrl: 'https://audioaidynamics.com/hpcp-chroma'
    },
  },
  {
    path: "/online-metronome",
    component: lazyLoad("metronome"),
    name: "Online Metronome",
    meta: {
      requiresAuth: false,
      layout: SideMenuLayout,
      metaTags: MetronomeMetaTags,
      title: "Online Metronome | Metronome | Digital Metronome",
      canonicalUrl: 'https://audioaidynamics.com/online-metronome'
    },
  },
  {
    path: "/genre-finder",
    component: lazyLoad("GenreDetector"),
    name: "Genre Finder",
    meta: {
      requiresAuth: false,
      layout: SideMenuLayout,
      metaTags: GenreFinderTags,
      title: "Music Genre Finder | Music Genre | Find Song Genre",
      canonicalUrl: 'https://audioaidynamics.com/genre-finder'
    },
  },
  /* {
    path: "/audio-normalization",
    component: lazyLoad("AudioNormalization"),
    name: "Audio Normalziation",
    meta: {
      requiresAuth: false,
      layout: SideMenuLayout,
      metaTags: [],
      title: "Audio Normalization | Audio Normalizer | Enhance sound quality",
      canonicalUrl: 'https://audioaidynamics.com/audio-normalization'
    },
  }, */
  /* {
    path: "/loudness-optimization",
    component: lazyLoad("AudioLoudnessOptimization"),
    name: "Audio Loudness Optimization",
    meta: {
      requiresAuth: false,
      layout: SideMenuLayout,
      metaTags: AudioLoudnessOptimizationTags,
      title: "Audio Loudness Optimization",
      canonicalUrl: 'https://audioaidynamics.com/loudness-optimization'
    },
  }, 
  */
  /* {
    path: "/chat",
    component: lazyLoad("Chat"),
    name: "Bot chat",
    meta: {
      requiresAuth: false,
      layout: SideMenuLayout,
      metaTags: GenreFinderTags,
      title: "Bot chat",
      canonicalUrl: 'https://audioaidynamics.com/chat'
    },
  }, */
  {
    path: "/profile",
    component: lazyLoad("Profile"),
    name: "Profile",
    meta: {
      requiresAuth: true,
      layout: SideMenuLayout,
      metaTags: NoIndexMetaTags,
      title: "Profile",
    },
  },
  {
    path: "/settings",
    component: lazyLoad("Settings"),
    name: "Settings",
    meta: {
      requiresAuth: true,
      layout: SideMenuLayout,
      metaTags: NoIndexMetaTags,
      title: "Settings",
    },
  },
  {
    path: "/reset-password",
    component: lazyLoad("ForgotPassword"),
    name: "ForgotPassword",
    meta: {
      requiresAuth: false,
      layout: AppLayout,
      metaTags: NoIndexMetaTags,
      title: "Forgot Password",
    },
  },
  {
    path: "/verify-email",
    component: lazyLoad("VerifyEmail"),
    name: "VerifyEmail",
    meta: {
      requiresAuth: false,
      layout: AppLayout,
      metaTags: NoIndexMetaTags,
      title: "Verify Email",
    },
  },
  {
      path: "/roadmap",
      component: lazyLoad("Roadmap"),
      name: "Roadmap",
      meta: {
        requiresAuth: false,
        layout: AppLayout,
        metaTags: RoadmapTags,
        title: "Roadmap",
        canonicalUrl: 'https://audioaidynamics.com/roadmap'
      },
  },
  {
      path: "/donate",
      component: lazyLoad("Donate"),
      name: "Donate",
      meta: {
        requiresAuth: false,
        layout: AppLayout,
        metaTags: DonateMetaTags,
        title: "Donate",
        canonicalUrl: 'https://audioaidynamics.com/donate'
      },
  },
  // { path: '/spotify-login', component:  lazyLoad('SpotifyLogin'), name: 'SpotifyLogin', meta: { requiresAuth: false, layout: AppLayout } },
  {
    path: "/:pathMatch(.*)*",
    component: lazyLoad("Home"),
    meta: {
      requiresAuth: false,
      layout: AppLayout,
      metaTags: HomeMetaTags,
      title: "Free Online Audio AI Tools by Audio AI Dynamics",
      canonicalUrl: 'https://audioaidynamics.com/'
    },
  },
];
export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  strict: true,
  scrollBehavior(to: any) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    }
  },
});
trackRouter(router);
let timerId: any = null
router.beforeEach((to: any, _from: any, next: any) => {
  updateSeo(to);
  const accessToken = getCookie("access_token");
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    if (accessToken) {
      next();
    } else {
      next({ name: "home", query: {redirect_url: to.fullPath, login: true} });
    }
  } else {
    next();
  }
});
router.afterEach((_to: any, _from: any, _next: any) => {
  if (timerId !== null) {
    clearTimeout(timerId)
  }
  timerId = setTimeout(() => {
    storeAnalytics({ entry_url: _to.fullPath, is_returning: localStorage.getItem('returnU') === 'true' ? true : false }, false)
  }, 5000)

})
const updateSeo = (to: any) => {
  const title = document.getElementsByTagName("title");
  const head = document.getElementsByTagName("head")[0]
  const canonicalUrl = to.meta.canonicalUrl;
  const allCustomTags = head.querySelectorAll('[data-aaid-meta]')
  allCustomTags.forEach((el: any) => {
    head.removeChild(el)
  })
  if (title && to.meta.title) {
    title[0].innerText = to.meta.title;
  }
    if (canonicalUrl) {
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
            link = document.createElement('link');
            link.setAttribute('rel', 'canonical');
            document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
    }
  if (to.meta.metaTags) {
    to.meta.metaTags.forEach((e: any) => {
      const attributes = []
      for (var key in e) {
        if (e.hasOwnProperty(key)) {
          attributes.push({key: key, value: e[key]})
        }
      }
      const el = document.createElement('meta')
      attributes.forEach((attr: any) => {
        el.setAttribute(attr.key, attr.value)
      })
      el.setAttribute('data-aaid-meta', '')
      head.appendChild(el)
    })
  }
};
