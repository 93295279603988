import baseEntityService from '../services/baseEntityService'

export default class RegisterService extends baseEntityService<any> {
  private static instance: RegisterService

  private constructor() {
    super('api/register')
  }

  public static getInstance(): RegisterService {
    if (!RegisterService.instance)
      return new RegisterService()

    return RegisterService.instance
  }

  public verifyEmail(url:string) {
    return this.getRequest(url)
  }

  public resendVerificationEmail(dto: any) {
    return this.postRequest('api/email/verification-notification', dto)
  }
}
