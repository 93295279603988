import type { IUser } from '../models/UserModel'
import baseEntityService from './baseEntityService'

export default class MeService extends baseEntityService<IUser> {
  private static instance: MeService

  private constructor() {
    super('api/users/me')
  }

  public static getInstance(): MeService {
    if (!MeService.instance)
      return new MeService()

    return MeService.instance
  }
}
